import React from "react"
import { Box } from "@mui/material"
import ReactMarkdown from "react-markdown"

const getBaselineStyling = ({ palette }) => ({
  "& > *": {
    m: 0,
  },
  " & > h1, h2, h3, h4, h5, h6": {
    mb: 2,
  },
  " & > p:not(:last-child)": {
    mb: "calc(1.5 * 1.375rem)",
  },
  "& > h1": {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: { xs: "1.3462rem", sm: "2.5rem", lg: "2.75rem" },
    lineHeight: 1.3,
  },
  "& > h2": {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: { xs: "1.3462rem", sm: "2.3077rem", lg: "2.5rem" },
    lineHeight: 1.3,
  },
  "& > h3": {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: { xs: "1.3462rem", sm: "1.9231rem", lg: "2.25rem" },
    lineHeight: 1.3,
  },
  "& > h4": {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: { xs: "1.25rem", sm: "1.7875", lg: "1.9643rem" },
    lineHeight: 1.4,
  },
  "& > h5": {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: { xs: "1.25rem", sm: "1.6071rem", lg: "1.75rem" },
    lineHeight: 1.4,
  },
  "& > h6": {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: { xs: "1rem", lg: "1.125rem" },
    lineHeight: 1.3,
  },
  "& > p, li": {
    color: palette.text.secondary,
    fontSize: { xs: "1rem", sm: "1.1667rem", md: "1.333rem" },
    fontWeight: 400,
    lineHeight: 1.5,
  },
  ul: {
    mb: "12px !important",
  },
  "ul::not(:first-child)": {
    mt: "calc(-1.5 * 1.125rem) !important",
  },
})
const RichText = ({ children, sx = {} }) => {
  return (
    <Box sx={theme => ({ ...getBaselineStyling(theme), ...sx })}>
      <ReactMarkdown>{children}</ReactMarkdown>
    </Box>
  )
}

export default RichText
