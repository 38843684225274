import React from "react"
import { Box, Typography } from "@mui/material"
import { graphql } from "gatsby"

import { GoPrimitiveDot } from "react-icons/go"

import Layout from "../../components/layout"
import RichText from "../../components/RichText"
import ContactForm from "../../components/ContactForm"
import FeaturedResources from "../../components/featured-resources"

const JobOpeningPage = ({
  data: {
    strapiJobOpening: {
      header,
      tags,
      jobDescription: {
        data: { jobDescription },
      },
      neededSkills,
      mainResponsibilities,
    },
    allStrapiArticle: { nodes: featuredArticles },
  },
}) => {
  return (
    <Layout>
      <Box
        mt={{ xs: 1, lg: 9 }}
        display="flex"
        flexDirection="column"
        mx="auto"
        maxWidth={800}
        gap={5}
      >
        <Box display="flex" flexDirection="column" gap={2.5} mb={2.5}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="overline"
              fontWeight={500}
              color="primary.main"
            >
              Job description
            </Typography>
            <Typography variant="h1">{header}</Typography>
          </Box>

          <Box display="flex" flexWrap="wrap" gap={2.5}>
            {tags.map(({ content, image }, i) => (
              <Box
                // flex={{ xs: "1 100%", sm: "1 40%", lg: 1 }}
                borderRadius={0.5}
                bgcolor="primary.light"
                display="flex"
                gap={1}
                key={i}
                alignItems="center"
                px={1}
                py={0.5}
              >
                <img src={image.localFile.publicURL} />
                <Typography variant="body1" color="primary.main">
                  {content}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <RichText>{jobDescription}</RichText>

        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {neededSkills.header}
          </Typography>

          {neededSkills.items.map(({ content }) => (
            <Box key={content} display="flex" sx={{ svg: { mt: 0.5, mr: 1 } }}>
              <GoPrimitiveDot color={neededSkills.bulletPointColor} size={20} />
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ flex: 1 }}
              >
                {content}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {mainResponsibilities.header}
          </Typography>

          {mainResponsibilities.items.map(({ content }) => (
            <Box key={content} display="flex" gap={1} sx={{ svg: { mt: 0.5 } }}>
              <GoPrimitiveDot color={neededSkills.bulletPointColor} size={20} />
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ flex: 1 }}
              >
                {content}
              </Typography>
            </Box>
          ))}
        </Box>

        <Typography variant="h3">Apply for the position</Typography>

        <ContactForm
          hasCV
          postURI="careers"
          fileInputLabel="Add CV"
          fileFieldName="CV"
          sx={{ boxShadow: 0, border: "1px solid", borderColor: "divider" }}
        />
      </Box>

      <FeaturedResources
        resourceURI="/articles"
        overline="Case studies"
        title="Featured resources"
        resources={featuredArticles}
      />
    </Layout>
  )
}

export const query = graphql`
  query strapiJobOpening($strapi_id: Int) {
    strapiJobOpening(strapi_id: { eq: $strapi_id }) {
      strapi_id
      header
      tags {
        content
        image {
          localFile {
            publicURL
            ext
          }
        }
      }
      jobDescription {
        data {
          jobDescription
        }
      }
      mainResponsibilities {
        header
        bulletPointColor
        items {
          content
        }
      }
      neededSkills {
        header
        bulletPointColor
        items {
          content
        }
      }
    }

    allStrapiArticle(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      nodes {
        strapi_id
        readTime
        publishedAt
        header {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            url
          }
        }
      }
    }
  }
`

export default JobOpeningPage
